<template>
  <div>
    <div
      class="label font-weight-bold label-lg label-inline"
      v-bind:class="[statusBinding.variant, 'mb-1']"
      style="width: max-content"
    >
      {{ statusBinding.text }}
    </div>
    <div
      v-if="performer && timestamp && status !== statusEnum.PENDING"
      :class="statusBinding.textVariant"
    >
      <p class="mb-0">{{ performer }}</p>
      <p>{{ timestamp }}</p>
    </div>
  </div>
</template>

<script>
import { TRANSFER_STORE_STATUS } from '@/utils/constants.js';
import { TRANSFER_STORE_FORM_STATUS } from '@/utils/enum.js';
export default {
  props: ['status', 'timestamp', 'performer'],
  data() {
    return {
		statusBinding : null
	};
  },
  created() {    
    this.statusBinding = TRANSFER_STORE_STATUS.find(
      (item) => item.value === this.status
    );
    this.statusEnum = TRANSFER_STORE_FORM_STATUS;
  },
  watch: {
    status(newStatus) {     
      this.statusBinding = TRANSFER_STORE_STATUS.find(
        (item) => item.value === newStatus
      );
    },
  },
};
</script>
