<template>
  <div class="upsert-employee">
    <KTCodePreview v-bind:title="''">
      <template v-slot:preview>
        <b-card
          no-body
          class="full-width"
        >
          <div
            class="wizard wizard-4"
            id="kt_wizard_v4"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Nav-->
            <div
              class="wizard-nav"
              style="background-color: #eef0f8"
            >
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Thông tin chung</div>
                    </div>
                  </div>
                </div>
                <div
                  class="wizard-step ml-1"
                  data-wizard-type="step"
                  v-if="checkPermission('SALARY_REPORT')"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Hồ sơ nhân sự</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--begin: Wizard Body-->
            <!-- Thông tin chung -->
            <div
              class="general-info"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <GeneralInfo
                :generalInfo="generalInfo"
                :categories="categories"
                @onSelectedStore="onSelectedStore"
                @onInputChange="onInputChange"
              />
            </div>
            <!-- Hồ sơ nhân sự -->
            <div
              class="personal-record"
              data-wizard-type="step-content"
            >
              <EmployeeRecord
                :generalInfo="generalInfo"
                @onSelectEmployeeStore="onSelectEmployeeStore"
                @onChangeEmployeeStore="onChangeEmployeeStore"
              />
            </div>
          </div>
        </b-card>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSubmit"
        >Lưu</b-button>
        <router-link
          to="/employees"
          tag="button"
        >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
          >Hủy</b-button>
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>
<style lang="css" scoped>
.tabs /deep/ .nav-item a {
  font-size: 1rem;
  font-weight: 600;
}
.upsert-employee /deep/ .card-body {
  padding: 0;
}
.upsert-employee .general-info,
.personal-record {
  padding: 20px;
}
</style>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import EmployeeRecord from '@/view/components/employee/EmployeeRecord.vue';
import GeneralInfo from '@/view/components/employee/GeneralInfo.vue';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';

import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

import KTWizard from '@/assets/js/components/wizard';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { checkPermission } from '../../../utils/saveDataToLocal';
import KTUtil from '@/assets/js/components/util';

export default {
  mixins: [validationMixin],
  data() {
    return {
      categories: [],
      generalInfo: {
        id: '',
        selectedCates: [],
        fullName: '',
        mobile: '',
        birthDay: '',
        email: '',
        position: {
          selected: null,
          options: [],
        },
        searchStore: '',
        listRegion: [],
        listArea: [],
        selectedArea: null,
        selectedRegion: null,
        code: '',
        listStoreId: [],
        listStores: [],
        listDepartment: [],
        selectedDepartment: null,
        listTransferStoreForms: [],
        loadingTransferStoreForm: false,
        filteredOptions: [],
        employeeStoreOptions: [],
        employeeStores: [],
        dpConfigs: {
          date: {
            format: 'DD/MM/YYYY',
            useCurrent: false,
            showClear: true,
            showClose: true,
            minDate: false,
            maxDate: false,
            locale: 'vi',
            sideBySide: true,
          },
        },
        active: {
          selected: 1,
          options: [
            { value: null, text: 'Hãy chọn trạng thái' },
            { value: '1', text: 'Hoạt động' },
            { value: '0', text: 'Không hoạt động' },
          ],
        },
        employeeStoreName: '',
        employeeStoreId: null,
        personalAllowance: 0,
        selfAllowance: 0,
        dependent: 0,
        commencementDate: null,
        personalIncomeTax: 1,
        standardWorkingDay: 0,
        standardWorkingHour: 0,
      },
    };
  },
  components: {
    EmployeeRecord,
    GeneralInfo,
    KTCodePreview,
  },
  watch: {
    'generalInfo.position.selected': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.generalInfo.employeeStoreOptions = [
          ...this.generalInfo.filteredOptions,
        ];
        this.employeeStores = [...this.generalInfo.employeeStoreOptions];
        const departmentUnit = this.employeeStores.find(
          (x) => x.id === this.generalInfo.employeeStoreId,
        );
        this.generalInfo.departmentUnit = departmentUnit
          ? departmentUnit.name
          : '';
      }
    },
  },
  created() {
    this.getCategories();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhân sự', route: '/employees' },
      { title: 'Danh sách nhân sự', route: '/employees' },
      { title: 'Cập nhật nhân sự' },
    ]);
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    this.id = this.$route.query.id;
    this.getEmployeeById();
    this.fetchStore();
    this.fetchArea();
    this.fetchRegion();
    this.fetchDeparment();
    this.fetchTransferStoreForms();
    this.fetchJobTitles();
  },
  methods: {
    getCategories() {
      ApiService.query('category').then(({ data }) => {
        if (data.status === 1) {
          this.categories = data.data.list_caterogy;
        }
      });
    },
    checkPermission,
    fetchJobTitles: async function () {
      ApiService.query('job-title-config/job-titles').then((response) => {
        this.generalInfo.position.options = [...response.data.data];
        this.generalInfo.position.options.unshift({
          id: null,
          name: 'Hãy chọn chức vụ',
        });
      });
    },
    updateParentData(data) {
      this.generalInfo = { ...data };
    },
    createEmployee: async function () {
      let empStoreIds = '-1';
      if (this.generalInfo.listStoreId.length > 0) {
        empStoreIds = '';
        this.generalInfo.listStoreId.forEach((element, index) => {
          if (index === this.generalInfo.listStoreId.length - 1) {
            empStoreIds += element.id;
          } else {
            empStoreIds += element.id + ', ';
          }
        });
      }
      const data = {
        fullName: this.generalInfo.fullName,
        code: this.generalInfo.code,
        birthDay: this.generalInfo.birthDay
          ? moment(this.generalInfo.birthDay, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        jobTitleId: this.generalInfo.position.selected,
        email: this.generalInfo.email,
        store: this.generalInfo.selectedStore,
        active: this.generalInfo.active.selected,
        mobile: this.generalInfo.mobile,
        areaId: this.generalInfo.selectedArea,
        regionId: this.generalInfo.selectedRegion,
        listStoreId: empStoreIds,
        departmentId: this.generalInfo.selectedDepartment,
        personalAllowance: this.generalInfo.personalAllowance,
        selfAllowance: this.generalInfo.selfAllowance,
        dependent: this.generalInfo.dependent,
        commencementDate: this.generalInfo.commencementDate
          ? moment(this.generalInfo.commencementDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            )
          : null,
        isPayPersonalIncomeTax: this.generalInfo.personalIncomeTax,
        transportationAllowance: this.generalInfo.transportationAllowance,
        employeeStoreId: this.generalInfo.employeeStoreId,
        standardWorkingHour: Number(this.generalInfo.standardWorkingHour) || 0,
        standardWorkingDay: Number(this.generalInfo.standardWorkingDay) || 0,
        positionSalary: Number(this.generalInfo.positionSalary) || 0,
        insurancePaidSalary: Number(this.generalInfo.insurancePaidSalary) || 0,
        positionAllowance: Number(this.generalInfo.positionAllowance) || 0,
        attendanceAllowance: Number(this.generalInfo.attendanceAllowance) || 0,
        dualJobAllowance: Number(this.generalInfo.dualJobAllowance) || 0,
        collaborateAllowance:
          Number(this.generalInfo.collaborateAllowance) || 0,
        beautyAllowance: Number(this.generalInfo.beautyAllowance) || 0,
        happyCustomerAllowance:
          Number(this.generalInfo.happyCustomerAllowance) || 0,
        unionDues: Number(this.generalInfo.unionDues) || 0,
        shuInsuranceDeduction:
          Number(this.generalInfo.shuInsuranceDeduction) || 0,
        categories: this.generalInfo.selectedCates || [],
      };

      ApiService.post('employees/v2', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.$router.push({
              name: 'list-employee',
            });
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    fetchTransferStoreForms: async function () {
      this.generalInfo.loadingTransferStoreForm = true;
      if (!this.generalInfo.id) return;

      ApiService.query(
        `transfer-store-forms/get-by-employee/${this.generalInfo.id}`,
      )
        .then((res) => {
          const data = res.data.data;
          this.generalInfo.listTransferStoreForms = data;
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        })
        .finally(() => {
          this.generalInfo.loadingTransferStoreForm = false;
        });
    },
    onContext(ctx) {
      this.generalInfo.formatted = ctx.selectedFormatted;
      this.generalInfo.selected = ctx.selectedYMD;
    },
    fetchStore: async function () {
      this.generalInfo.options = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.generalInfo.listStores = stores;
        this.generalInfo.options = this.generalInfo.filteredOptions = [
          ...stores,
        ];
      });
    },
    onSubmit() {
      if (this.id) {
        return this.updateEmployee();
      }
      return this.createEmployee();
    },
    reset() {
      this.$refs.form.reset();
    },
    updateEmployee: async function () {
      let empStoreIds = '-1';
      if (this.generalInfo.listStoreId.length > 0) {
        empStoreIds = '';
        this.generalInfo.listStoreId.forEach((element, index) => {
          if (index === this.generalInfo.listStoreId.length - 1) {
            empStoreIds += element.id;
          } else {
            empStoreIds += element.id + ', ';
          }
        });
      }
      const data = {
        id: this.generalInfo.id,
        fullName: this.generalInfo.fullName.trim(),
        birthDay:
          this.generalInfo.birthDay.length > 0
            ? moment(this.generalInfo.birthDay, 'DD/MM/YYYY').format(
                'yyyy-MM-DD',
              )
            : null,
        jobTitleId: this.generalInfo.position.selected,
        email: this.generalInfo.email ? this.generalInfo.email.trim() : '',
        store: this.generalInfo.selectedStore,
        active: this.generalInfo.active.selected,
        mobile: this.generalInfo.mobile,
        areaId: this.generalInfo.selectedArea,
        regionId: this.generalInfo.selectedRegion,
        listStoreId: empStoreIds,
        code: this.generalInfo.code ? this.generalInfo.code.trim() : '',
        departmentId: this.generalInfo.selectedDepartment,
        personalAllowance: this.generalInfo.personalAllowance,
        selfAllowance: this.generalInfo.selfAllowance,
        dependent: this.generalInfo.dependent,
        commencementDate: this.generalInfo.commencementDate
          ? moment(this.generalInfo.commencementDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            )
          : null,
        personalIncomeTax: this.generalInfo.personalIncomeTax,
        departmentUnit: this.generalInfo.selectedDepartmentUnit,
        transportationAllowanceAmount:
          this.generalInfo.transportationAllowanceAmount,
        isPayPersonalIncomeTax: this.generalInfo.personalIncomeTax,
        transportationAllowance: this.generalInfo.transportationAllowance,
        employeeStoreId: this.generalInfo.employeeStoreId,
        standardWorkingHour: Number(this.generalInfo.standardWorkingHour) || 0,
        standardWorkingDay: Number(this.generalInfo.standardWorkingDay) || 0,
        positionSalary: Number(this.generalInfo.positionSalary) || 0,
        insurancePaidSalary: Number(this.generalInfo.insurancePaidSalary) || 0,
        positionAllowance: Number(this.generalInfo.positionAllowance) || 0,
        attendanceAllowance: Number(this.generalInfo.attendanceAllowance) || 0,
        dualJobAllowance: Number(this.generalInfo.dualJobAllowance) || 0,
        collaborateAllowance:
          Number(this.generalInfo.collaborateAllowance) || 0,
        beautyAllowance: Number(this.generalInfo.beautyAllowance) || 0,
        happyCustomerAllowance:
          Number(this.generalInfo.happyCustomerAllowance) || 0,
        unionDues: Number(this.generalInfo.unionDues) || 0,
        shuInsuranceDeduction:
          Number(this.generalInfo.shuInsuranceDeduction) || 0,
        employeeFileId: this.generalInfo.employeeFileId,
        categories: this.generalInfo.selectedCates || [],
      };

      ApiService.put('employees/v2', data).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          makeToastSuccess(message);
          this.$router.push({
            name: 'list-employee',
          });
        } else {
          makeToastFaile(message);
        }
      });
    },
    getEmployeeById: async function () {
      if (!this.id) return;
      ApiService.query('employees' + '/' + this.id).then((response) => {
        const dataRes = response.data.data;
        this.generalInfo.id = dataRes.id;
        this.generalInfo.fullName = dataRes.fullName;
        this.generalInfo.birthDay = dataRes.birthDay
          ? moment(String(dataRes.birthDay)).format('DD-MM-YYYY')
          : '';
        this.generalInfo.position.selected = dataRes.jobTitleId;
        this.generalInfo.email = dataRes.email;
        this.generalInfo.selectedStore = dataRes.storeId;
        this.generalInfo.employeeStoreId = dataRes.employeeStoreId;
        this.generalInfo.employeeStoreName = dataRes.employeeStoreName;
        this.generalInfo.active.selected = dataRes.isActive === true ? 1 : 0;
        this.generalInfo.mobile = dataRes.mobile;
        this.generalInfo.searchStore = dataRes.storeName;
        this.generalInfo.selectedArea = dataRes.areaId;
        this.generalInfo.selectedRegion = dataRes.regionId;
        this.generalInfo.code = dataRes.code;
        this.generalInfo.listStoreId = dataRes.listStore;
        this.generalInfo.selectedDepartment = dataRes.departmentId;
        this.generalInfo.selectedDepartment = dataRes.departmentId;
        this.generalInfo.selectedDepartment = dataRes.departmentId;
        this.generalInfo.selectedDepartment = dataRes.departmentId;
        this.generalInfo.selectedDepartment = dataRes.departmentId;
        this.generalInfo.selectedDepartment = dataRes.departmentId;
        this.generalInfo.personalAllowance = dataRes.personalAllowance;
        this.generalInfo.selfAllowance = dataRes.selfAllowance;
        this.generalInfo.dependent = dataRes.dependent;
        this.generalInfo.transportationAllowance =
          dataRes.transportationAllowance;
        this.generalInfo.commencementDate = dataRes.commencementDate
          ? moment(String(dataRes.commencementDate)).format('DD-MM-YYYY')
          : null;
        this.generalInfo.personalIncomeTax = dataRes.isPayPersonalIncomeTax;
        this.generalInfo.filteredOptions = [...this.generalInfo.options];
        this.generalInfo.beautyAllowance = dataRes.beautyAllowance;
        this.generalInfo.happyCustomerAllowance =
          dataRes.happyCustomerAllowance;
        this.generalInfo.selfAllowance = dataRes.selfAllowance;
        this.generalInfo.dependent = dataRes.dependent;
        this.generalInfo.unionDues = dataRes.unionDues;
        this.generalInfo.shuInsuranceDeduction = dataRes.shuInsuranceDeduction;
        this.generalInfo.standardWorkingHour = dataRes.standardWorkingHour;
        this.generalInfo.standardWorkingDay = dataRes.standardWorkingDay;
        this.generalInfo.positionSalary = dataRes.positionSalary;
        this.generalInfo.insurancePaidSalary = dataRes.insurancePaidSalary;
        this.generalInfo.positionAllowance = dataRes.positionAllowance;
        this.generalInfo.attendanceAllowance = dataRes.attendanceAllowance;
        this.generalInfo.dualJobAllowance = dataRes.dualJobAllowance;
        this.generalInfo.collaborateAllowance = dataRes.collaborateAllowance;
        this.generalInfo.employeeFileId = dataRes.employeeFileId;
        this.generalInfo.selectedCates = [...dataRes.categories];
      });
    },
    onInputChange(text) {
      const filteredData = this.generalInfo.options
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);
      this.generalInfo.filteredOptions = [...filteredData];
    },
    onSelectedStore(option) {
      this.generalInfo.searchStore = option.item.name;
      this.generalInfo.selectedStore = option.item.id;
    },
    onSelectEmployeeStore(option) {
      this.generalInfo.employeeStoreName = option.item.name;
      this.generalInfo.employeeStoreId = option.item.id;
    },
    onChangeEmployeeStore(text) {
      const filteredData = this.employeeStores
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, 10);
      this.generalInfo.employeeStoreOptions = [...filteredData];
    },
    fetchArea: async function () {
      this.generalInfo.listArea = [];
      ApiService.get('/area').then((response) => {
        this.generalInfo.listArea = response.data.data;
      });
    },
    fetchRegion: async function () {
      this.generalInfo.listRegion = [];
      ApiService.get('/region').then((response) => {
        this.generalInfo.listRegion = response.data.data;
      });
    },
    onChangeListStore() {
      this.generalInfo.options = [];
      this.generalInfo.listStoreId.map((element) => {
        this.generalInfo.options.push(element);
      });
      let tmp = [{ data: this.generalInfo.options }];
      this.generalInfo.filteredOptions = tmp;
    },
    fetchDeparment: async function () {
      this.generalInfo.listDeparment = [];
      ApiService.get('/employees/departments').then((response) => {
        this.generalInfo.listDepartment = response.data.data;
      });
    },
  },
  validations: {
    fullName: {
      required,
      minLength: minLength(3),
    },
  },
};
</script>
